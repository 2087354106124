jQuery(window).ready(function ($) {
    // options for site script
    var options = {
        fixedMenubar: {
            showAt: 300,
            selector: '.js-menubar-fixed',
            hiddenClass: '-hidden',
            fadeDuration: 140
        }
    };

    var s = skrollr.init({
        forceHeight: false
    });
    if (!s.isMobile()) {
        skrollr.menu.init(s, {
            animate: true,
            easing: 'sqrt',
            updateUrl: true
        });
    } else {
        s.destroy();
    }

    // handle scroll event and display fixed main menu when
    // page is scrolled more than 100px down
    $(window).scroll(function() {
        var scrollPos = $(window).scrollTop(),
            fixedMenubar = $(options.fixedMenubar.selector);
        if (scrollPos >= options.fixedMenubar.showAt) {
            if (fixedMenubar.hasClass('-hidden')) {
                fixedMenubar.fadeIn(options.fixedMenubar.fadeDuration, function() {
                    fixedMenubar.removeClass('-hidden');
                });
            }
        } else {
            if (!fixedMenubar.hasClass('-hidden')) {
                fixedMenubar.fadeOut(options.fixedMenubar.fadeDuration, function() {
                    fixedMenubar.addClass('-hidden');
                });
            }
        }
    });
});